exports = module.exports = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Header_header__1VCKf{\r\n    background: var(--tg-theme-bg-color);\r\n    z-index: 999;\r\n    position: fixed;\r\n    width: 100%;\r\n    height: 50px;\r\n    align-items: center;\r\n    display: flex;\r\n    justify-content: center;\r\n    grid-gap: 5px;\r\n    gap: 5px;\r\n    padding: 15px;\r\n}\r\n\r\n.Header_seacrchcontainer__2AvDn {\r\n    width: 100%;\r\n}\r\n\r\n.Header_input__1s5iR {\r\n    font-size: 16px;\r\n    border-radius: 5px;\r\n    width: 100%;\r\n    padding: 10px;\r\n    color: var(--tg-theme-text-color);\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    border: 2px solid rgba(255,128,53,255);\r\n    outline: none;\r\n\r\n}\r\n\r\n.Header_hideinput__3RLhd {\r\n    border-radius: 13px;\r\n    border: 0;\r\n    width: 100%;\r\n    padding: 10px;\r\n    color: var(--tg-theme-text-color);\r\n    background-color: var(--tg-theme-bg-color);\r\n}\r\n\r\n\r\n.Header_username__bQjFG{\r\n    margin-left: auto;\r\n}\r\n\r\n.Header_header__1VCKf shopping_cart{\r\n    padding: 10px;\r\n    display: flex;\r\n}\r\n\r\n\r\n.Header_searchicon__367-B{\r\n\r\n\r\n    margin-left: 10px;\r\n\r\n;\r\n}\r\n\r\n.Header_shopping_cart__1d8-v button{\r\n    background: none;\r\n    border: none;\r\n}\r\n\r\n.Header_shopping_cart__1d8-v img{\r\n    height: 50%;\r\n    width: 50%;\r\n}", ""]);

// Exports
exports.locals = {
	"header": "Header_header__1VCKf",
	"seacrchcontainer": "Header_seacrchcontainer__2AvDn",
	"input": "Header_input__1s5iR",
	"hideinput": "Header_hideinput__3RLhd",
	"username": "Header_username__bQjFG",
	"searchicon": "Header_searchicon__367-B",
	"shopping_cart": "Header_shopping_cart__1d8-v"
};