exports = module.exports = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SignUp_form__21rBv {\r\n    padding: 14vw 5vw 10vw 5vw;\r\n    display: flex;\r\n    color: var(--tg-theme-text-color);\r\n    flex-direction: column;\r\n    width: 100%;\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    background-color: var(--tg-theme-bg-color);\r\n\r\n}\r\n\r\n.SignUp_input__2EsVJ {\r\n    font-size: 16px;\r\n    border-radius: 5px;\r\n    width: 100%;\r\n    padding: 10px;\r\n    color: var(--tg-theme-text-color);\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    margin-top: 15px;\r\n}\r\n\r\n.SignUp_select__1q_05 {\r\n    font-size: 16px;\r\n    padding: 10px;\r\n    margin-top: 15px;\r\n}\r\n\r\n.SignUp_fakeblock__N4zzb{\r\n    padding-top: 450px;\r\n}\r\n\r\n.SignUp_singButton__5OUHR{\r\n    background-color: rgba(255,128,53,255);\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    width: 96%;\r\n    padding: 3vw;\r\n    border-radius: 5px;\r\n    border: None;\r\n}\r\n\r\n.SignUp_topay__xKyM2{\r\n    font-weight: bold;\r\n    text-align: center;\r\n    margin-top: 5vw;\r\n    color: rgba(19, 189, 50, 1)\r\n}\r\n\r\n.SignUp_buttonList__114_b{\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin-top: 10px;\r\n    justify-content: space-between;\r\n}", ""]);

// Exports
exports.locals = {
	"form": "SignUp_form__21rBv",
	"input": "SignUp_input__2EsVJ",
	"select": "SignUp_select__1q_05",
	"fakeblock": "SignUp_fakeblock__N4zzb",
	"singButton": "SignUp_singButton__5OUHR",
	"topay": "SignUp_topay__xKyM2",
	"buttonList": "SignUp_buttonList__114_b"
};