exports = module.exports = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ProductList_form__3XoIA {\r\n    display: flex;\r\n    color: var(--tg-theme-text-color);\r\n    flex-direction: column;\r\n    width: 100%;\r\n    padding: 10px;\r\n    background-color: var(--tg-theme-bg-color);\r\n}\r\n\r\n.ProductList_input__1LPPl {\r\n    width: 100%;\r\n    padding: 10px;\r\n    color: black;\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    margin-top: 0px;\r\n}\r\n\r\n.ProductList_select__vFvvO {\r\n    padding: 10px;\r\n    margin-top: 15px;\r\n}\r\n\r\n.ProductList_postlist__1-yoy{\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin-top: 10px;\r\n    justify-content: space-between;\r\n}\r\n.ProductList_fakeblock__3X6OW{\r\n    padding-top: 450px;\r\n}\r\n.ProductList_maintext__B_DMk{\r\n    text-align: center;\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    /*margin-top: 30px;*/\r\n}\r\nhtml {\r\n    scroll-behavior: smooth;\r\n}\r\n.ProductList_AuthContainer__9Eja_{\r\n    font-family: 'Montserrat';\r\n    margin-top: 45px;\r\n}\r\n\r\n\r\n.ProductList_AuthButtonContainer__3KjD5{\r\n    margin-top: 45px;\r\n    display: flex;\r\n    justify-content: space-around;\r\n    flex-direction: row;\r\n}\r\na.ProductList_text__2n7GU:active, \r\na.ProductList_text__2n7GU:hover,  \r\na.ProductList_text__2n7GU {\r\n    text-decoration: none;\r\n    color: #666;\r\n}\r\n", ""]);

// Exports
exports.locals = {
	"form": "ProductList_form__3XoIA",
	"input": "ProductList_input__1LPPl",
	"select": "ProductList_select__vFvvO",
	"postlist": "ProductList_postlist__1-yoy",
	"fakeblock": "ProductList_fakeblock__3X6OW",
	"maintext": "ProductList_maintext__B_DMk",
	"AuthContainer": "ProductList_AuthContainer__9Eja_",
	"AuthButtonContainer": "ProductList_AuthButtonContainer__3KjD5",
	"text": "ProductList_text__2n7GU"
};