exports = module.exports = require("../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SignIn_form__1H_-P {\r\n    padding: 14vw 5vw 10vw 5vw;\r\n    display: flex;\r\n    color: var(--tg-theme-text-color);\r\n    flex-direction: column;\r\n    width: 100%;\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    background-color: var(--tg-theme-bg-color);\r\n}\r\n\r\n.SignIn_input__3-bwk {\r\n    font-size: 16px;\r\n    border-radius: 5px;\r\n    width: 100%;\r\n    padding: 10px;\r\n    color: var(--tg-theme-text-color);\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    margin-top: 15px;\r\n}\r\n\r\n.SignIn_select__2j1Jo {\r\n    font-size: 16px;\r\n    padding: 10px;\r\n    margin-top: 15px;\r\n}\r\n\r\n\r\n\r\n.SignIn_singButton__GmTNC{\r\n    background-color: rgba(255,128,53,255);\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    width: 96%;\r\n    padding: 3vw;\r\n    border-radius: 5px;\r\n    border: None;\r\n}\r\n\r\n.SignIn_topay__3D9-M{\r\n    font-weight: bold;\r\n    text-align: center;\r\n    margin-top: 5vw;\r\n    color: rgba(19, 189, 50, 1)\r\n}\r\n.SignIn_fakeblock__1D0Bs{\r\n    padding-top: 450px;\r\n}\r\n.SignIn_buttonList__1Ip4d{\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin-top: 10px;\r\n    justify-content: space-between;\r\n}", ""]);

// Exports
exports.locals = {
	"form": "SignIn_form__1H_-P",
	"input": "SignIn_input__3-bwk",
	"select": "SignIn_select__2j1Jo",
	"singButton": "SignIn_singButton__GmTNC",
	"topay": "SignIn_topay__3D9-M",
	"fakeblock": "SignIn_fakeblock__1D0Bs",
	"buttonList": "SignIn_buttonList__1Ip4d"
};